import React from "react";
import Img from "gatsby-image";

const Image = ({ data, title = "", ...rest }) => {
  return data && data.childImageSharp ? (
    <Img alt={title} fluid={data.childImageSharp.fluid} {...rest} />
  ) : (
    <img alt={title} src={data.publicURL} {...rest} />
  );
};

export default Image;
