import { useEffect } from "react";

const onData = data => {
  if (!window.dataLayer) {
    console.warn("TicketShop tracking: No datalayer found");
    return;
  }
  switch (data.message) {
    case "fixr.widget:viewEventDetailsClicked":
      window.dataLayer.push({
        event: "oc_screen_view",
        screen_name: data.eventName
      });
      break;

    case "fixr.widget:ticketSelected":
      window.dataLayer.push({
        event: "oc_add_to_cart",
        items: [
          {
            name: data.ticketTypeName
          }
        ]
      });
      break;

    case "fixr.widget:checkout":
      window.dataLayer.push({
        event: "oc_begin_checkout",
        value: (
          data.ticketsNum *
          (data.ticketPrice + data.bookingFee)
        ).toString(),
        currency: data.currency,
        items: [
          {
            name: data.ticketName
          }
        ]
      });
      break;

    case "fixr.widget:purchaseCompleted":
      window.dataLayer.push({
        event: "oc_purchase",
        value: (
          data.numberOfTicket *
          (data.ticketPrice + data.bookingFee)
        ).toString(),
        currency: data.currency,
        tax: "0.00",
        shipping: "0.00",
        items: [
          {
            name: data.ticketTypeName,
            quantity: data.numberOfTicket,
            price: data.ticketPrice
          }
        ]
      });
      break;
    default:
  }
};

const useTicketshopTracking = () => {
  useEffect(() => {
    const onMessage = message => {
      if (message.origin === "https://fixr.co") {
        onData(message.data);
      }
    };

    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);
};

export default useTicketshopTracking;
