import React from "react";
import { graphql } from "gatsby";
import Layout from "components/layout";
import Image from "components/image";
import TicketShop from "components/ticket-shop";
import useTicketshopTracking from "hooks/use-ticketshop-tracking";
import styles from "./styles.module.css";

export const query = graphql`
  query CityPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        ticketshop
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 500, quality: 72) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
    }
  }
`;

const EventTemplate = ({ data }) => {
  useTicketshopTracking();
  const { title, featuredImage, ticketshop } = data.markdownRemark.frontmatter;
  return (
    <Layout title={title}>
      <div className={styles.eventContainer}>
        <header className={styles.eventHeader}>
          <Image className={styles.featureImage} data={featuredImage}></Image>
          <h1>{title}</h1>
        </header>
        <div
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        ></div>
        <TicketShop scriptString={ticketshop}></TicketShop>
      </div>
    </Layout>
  );
};

export default EventTemplate;
